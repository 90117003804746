import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription, take } from 'rxjs';
import { PaymentModalComponent } from '../components/payment-components/payment-modal/payment-modal.component';
import { IPaymentInstructions } from './entities/payments/payments.service';
import { environment } from 'src/environments/environment';
import { Language } from '../enums/utils/languages.enum';
import { I18nService, LanguageChangePriority } from './i18n.service';

export enum WebcomponentType {
  SHOPPING_CART_ICON = 'SHOPPING_CART_ICON',
  PRESENTER = 'PRESENTER',
  SHOP_ITEM = 'SHOP_ITEM',
  CURRENCY_CHANGER = 'CURRENCY_CHANGER',
  SHOPPING_CART_SUMMARY = 'SHOPPING_CART_SUMMARY',
};

export enum PresenterMode {
  PRELOAD = 'PRELOAD', // nic se nezobrazi, nic se nedeje, pouze pro optimalizaci performance, kdy se pouze nastartuje instance appky, ktera si stahne potrebne files
  BUY_BUTTON = 'BUY_BUTTON', // zakaznik si pres classu a data attribut nastavi element, po jehoz kliknuti se objevi modal s shopItemem
  EVENT = 'EVENT', // zobrazi cely event, v pripade samostatneho eventu rovnou REG_CARD, jinak TILEs
  // EVENT_ADMIN = 'EVENT_ADMIN', // pro zobrazeni v admin rozhrani - deprecated - admin rozhrani ma svoje zobrazeni
  ORGANIZATION_EVENTS = 'ORGANIZATION_EVENTS', // zobrazi vsechny eventy v ramci organizace
  EVENT_SCHEDULE = 'EVENT_SCHEDULE', // zobrazi sessionGroupy udalosti v rozrvhu
  ORGANIZATION_SCHEDULE = 'ORGANIZATION_SCHEDULE', // zobrazi sessionGroupy vsech udalosti v ramci organizace
  ORGANIZATION_ITEMS = 'ORGANIZATION_ITEMS', // zobrazi vsechny shopItemy v ramci organizace napric eventami
  CAROUSEL = 'CAROUSEL', // carousel s shopItemy
  CALENDAR_ROWS = 'CALENDAR_ROWS', // zobrazi shopitemy pod sebou (cirqueon)
  ITEM_DETAIL = 'ITEM_DETAIL', // zobrazeni detailu shopItemu (muze byt vice behu kurzu - typicky query podle code) (cirqueon)
  ITEM_RESERVATION = 'ITEM_RESERVATION', // rovnou zobrazi obsah modalu pro rezervaci shopItemu
};

@Injectable({
  providedIn: 'root'
})
export class WebcomponentsService implements OnDestroy {

  public newOrderCreated$ = new Subject<{
    paymentInstructions: IPaymentInstructions;
    orderId: number;
    orderUuid: string;
    openPaymentModal: boolean;
    companyOrder: boolean;
  }>();

  public shoppingCartChange$ = new Subject<void>();

  constructor(
    private i18nService: I18nService,
  ) {
    // handle events from webcomponents
    window.addEventListener('lektory_newOrderCreated', this.handleNewOrderCreated);
    window.addEventListener('lektory_shoppingCartChange', this.handleShoppingCartChange);
    window.addEventListener('message', (event) => {
      if (typeof event.data === 'string') {
        const msg = event.data.split('|');
        // response from getWebcomponentLanguage
        if (msg[0] === 'lektory_language') {
          const lang = msg[1];
          this.i18nService.useLanguage(lang as Language, LanguageChangePriority._1);
        }
      }
    })
  }

  public registerWebcomponent() {
    this.registerCustomElement('lektory-webcomponent', environment.webcomponentUrl);
  }

  public registerCustomElement(name: string, src: string, customContainer?: HTMLElement): void {
    // dynamically insert script (if doesn't already exist)
    if (!document.getElementById(name)) {
      const container = customContainer ? customContainer : document.body;
      const script = document.createElement('script');
      script.src = src;
      script.id = name;
      script.type = 'module';
      container.appendChild(script);
    }
  };

  getWebcomponentLanguage() {
    const msg = { type: 'webcomponent_lang', logicApplied: false };
    window.parent.window.postMessage(msg, '*');
  }

  handleLanguage(event: Event) {
    const customEvent = event as CustomEvent;

    const eventDetail = customEvent.detail as Language;

    this.i18nService.useLanguage(eventDetail, LanguageChangePriority._1);
  }

  handleNewOrderCreated = (event: Event) => {
    const customEvent = event as CustomEvent;

    const eventDetail = customEvent.detail;

    this.newOrderCreated$.next(eventDetail);
  }

  private handleShoppingCartChange = (event: Event) => {
    this.shoppingCartChange$.next();
  }

  ngOnDestroy(): void {
    window.removeEventListener('lektory_newOrderCreated', this.handleNewOrderCreated);
    window.removeEventListener('lektory_shoppingCartChange', this.handleShoppingCartChange);
    window.removeEventListener('lektory_language', this.handleLanguage);
  }
}
